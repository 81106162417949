import { computed, DestroyRef, inject, Injectable, signal } from '@angular/core';
import { LocalStorageService } from '@/common/localstorage.service';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Injectable({
	providedIn: 'root',
})
export class FavoriteService {
	readonly storedFavorites = signal<number[]>([]);
	readonly isFavoriteRoute = signal(false);

	readonly count = computed(() => this.storedFavorites()?.length);

	private readonly localStorageService = inject(LocalStorageService);
	private readonly destroyRef = inject(DestroyRef);

	constructor(private readonly router: Router) {
		this.router.events
			.pipe(
				filter((event) => event instanceof NavigationEnd),
				takeUntilDestroyed(this.destroyRef),
			)
			.subscribe((event) => this.isFavoriteRoute.set(event.url.includes('/ulubione')));
	}

	getFavoriteOffersFromStorage(): number[] {
		return this.localStorageService.getItem('favoriteOffers')
			? JSON.parse(this.localStorageService.getItem('favoriteOffers'))
			: [];
	}
}
